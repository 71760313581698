import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
// import Vuexstore from '@/components/vuexstore'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/grid',
    name: 'grid',
    title: '网格管理',
    component: () => import('../views/grid.vue')
  },
  {
    path: '/party',
    name: 'party',
    title: '党建引领',
    component: () => import('../views/partyBuild/index.vue')
  },
  {
    path: '/videos',
    name: 'videos',
    title: '视频监控',
    component: () => import('../views/videos.vue')
  },
  {
    path: '/3dmap',
    name: '3dmap',
    title: '3D地图',
    component: () => import('../views/3Dmap.vue')
  },
  {
    path: '/dataCenter',
    name: 'dataCenter',
    title: '数据中心',
    component: () => import('../dataCenter.vue')
  },
  {
    path: '/integrated',
    name: 'integrated',
    title: '数据中心',
    component: () => import('../integrated.vue')
  },
  {
    path: '/video',
    name: 'video',
    title: '监控中心',
    component: () => import('../video.vue')
  },
  {
    path: '/classRoom',
    name: 'classRoom',
    title: '监控中心',
    component: () => import('../classRoom.vue')
  },
  {
    path: '/agriculture',
    name: 'agriculture',
    title: '监控中心',
    component: () => import('../agriculture.vue')
  },
  {
    path: '/industry',
    name: 'industry',
    title: '数据中心',
    component: () => import('../views/industry.vue')
  },
  {
    path: '/industryService',
    name: 'industryService',
    title: '服务业数据分析',
    component: () => import('../views/industryService.vue')
  },
  {
    path: '/industryAgriculture',
    name: 'industryAgriculture',
    title: '农业业数据分析',
    component: () => import('../views/industryAgriculture.vue')
  },
  {
    path: '/newParty',
    name: 'newParty',
    title: '党建引领',
    component: () => import('../views/newParty.vue')
  },
  {
    path: '/worker',
    name: 'worker',
    title: '工业信息',
    component: () => import('../views/worker.vue')
  },
  {
    path: '/villageSituation',
    name: 'villageSituation',
    title: '村情信息',
    component: () => import('../villageSituation.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
