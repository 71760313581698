<template>
  <div class="contain">
  <div class="box_shadow">
    <div class="tab_box ">
      <div class="tab_contain" v-for="(item, index) in navlist" :key="index" @click="handleTab(index)" :class="item.titleIcon ? 'tab_con' : ''">
        <img class="tab_img" :src="item.icon" />
        <div class="tab_title">{{ item.tabName }}</div>
      </div>
    </div>
    </div>
    <div class="contain_bot">大连众晖科技发展有限公司</div>
    <h-login ref="loginmode" @login="submitForm"></h-login>
  </div>
</template>

<script>
// import pHead from '../components/pageHead.vue'
import hLogin from '../components/login.vue'
import { navlist } from '@/utils/index.js'
export default {
  components: {
    // pHead,
    hLogin
  },
  data() {
    return {
      tianInfo: {},
      year: '',
      navlist: [],
      num: '',
      isLogin: false,
      loginShow: false,
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [{ required: true, message: '请输入账户', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      pindex: ''
    }
  },
  methods: {
    handleTab(index) {
      let item = this.navlist[index]
      if (!item.route) {
        this.msgError('正在升级中~')
        return
      }
      if (!sessionStorage.getItem('userInfo')) {
        this.msgError('请先登录')
        this.pindex = index
        this.$refs.loginmode.show = true
        return
      }
      if (index != this.num) {
        this.num = index
      }

      // item.active = !item.active
      // this.$set(this.navlist, index, item)

      if (item.route) {
        this.$router.replace(item.route)
      }
    },
    //登录
    submitForm() {
      this.isLogin = true
      let pindex = this.pindex
      if ((!pindex && pindex != 0) || !this.navlist[pindex].route) {
        return
      } else if (this.navlist[pindex].route) {
        this.$router.replace(this.navlist[pindex].route)
      }
    },

    goLogin() {
      this.$refs.loginmode.pindex = ''
      this.$refs.loginmode.show = true
      // this.loginShow = true
    },
    //退出
    goOut() {
      this.num = 0
      sessionStorage.clear()
      this.isLogin = false
    }
  },
  created() {
    this.navlist = navlist
  }
}
</script>

<style lang="scss" scped>
:root {
  // --color: rgba(12, 89, 240, 0.5);
  --color: rgba(0, 0, 0, 0.6);
  // --color:inear-gradient(180deg, #0b1c9d 0%, #0E5FFF 100%);
  --lineColor: rgba(63, 64, 65, 0.2);
}

.contain {
  width: 100%;
  height: 100%;
  background-image: url('../assets/img/bg1.gif');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
  overflow: hidden;

  .box_shadow {
    height: 100%;
    width: 100%;
    background: #0006;
    position: fixed;
    z-index: 0;
  }

  .tab_box {
    display: flex;
    flex-wrap: wrap;
    margin: 11% auto;
    width: 65%;
    height: 70%;
    justify-content: space-between;
  }
  .tab_contain {
    width: 215px;
    height: 215px;
    background: linear-gradient(rgba(0, 0, 0, 0.63), rgba(0, 0, 0, 0.1));
    // background: rgba(2, 0, 77, 0.7);
    border: 2px solid rgba(0, 0, 0, 0.4);
    // box-shadow: inset 0px 0px 34px rgba(17, 40, 255, 0.66);
    border-radius: 108px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2.5% 6%;
    cursor: pointer;
    position: relative;
  }
  .tab_contain:hover {
    background: linear-gradient(180deg, #8d8d8d 0%, rgba(108, 109, 110, 0) 100%);
    border: 2px solid rgba(0, 0, 0, 0.4);
  }
  .tab_con:hover {
    background: linear-gradient(180deg, #8d8d8d 0%, rgba(108, 109, 110, 0) 100%);
    border: 2px solid #ff0000;
  }
  .tabactive {
    // background: linear-gradient(180deg, #0e5fff 0%, rgba(14, 95, 255, 0.427) 100%);
    background: linear-gradient(180deg, #8d8d8d 0%, rgba(108, 109, 110, 0) 100%);
    border: 2px solid rgba(0, 0, 0, 0.4);
  }
  // .tab_img {
  //   width: 76px;
  //   height: 57px;
  // }
  .tab_title {
    font-size: 20px;
    letter-spacing: 2px;
    text-shadow: 0px 0px 4px #434344;
    margin-top: 20px;
  }
  .tab_contain::before {
    border: 1px solid transparent;
    border-left: 1px solid var(--color);
    border-right: 1px solid var(--color);
    border-radius: 50%;
    // border-image: linear-gradient(red, yellow) 30 30;
  }
  .tab_contain::after {
    border: 2px dashed transparent;
    border-left: 2px dashed rgba(0, 0, 0, 0.4);
    border-right: 2px dashed rgba(0, 0, 0, 0.4);
    border-radius: 50%;
  }

  .tab_contain::before {
    content: '';
    width: 265px;
    height: 255px;
    position: absolute;
  }
  .tab_contain::after {
    content: '';
    width: 250px;
    height: 250px;
    position: absolute;
  }
  .tab_contain::before,
  .tab_contain::after {
    animation: turn2 3.35s linear infinite;
  }
  @keyframes turn2 {
    100% {
      transform: rotateZ(1turn);
    }
  }
  .contain_bot {
    width: 100%;
    height: auto;
    text-align: center;
    position: fixed;
    bottom: 0;
    margin-bottom: 10px;
    font-size: 16px;
  }
}
.ql-editor li[data-list='bullet'] > .ql-ui:before {
  margin-right: 5px;
  content: '\2022';
}
.ql-editor li[data-list='bullet']::before {
  width: 0;
  height: 0;
  content: '';
}
</style>
<style lang="scss">
/* 弹窗样式*/
.contain /deep/.el-dialog {
  background: transparent !important;
  border: 1px solid #396f92;
}
.contain /deep/.el-dialog__header {
  text-align: center;
}
.contain /deep/.el-dialog__title {
  color: #49df00;
  font-weight: bold;
  letter-spacing: 5px;
  font-style: italic;
}
.contain /deep/.el-input__inner {
  background: transparent;
  color: #fff;
}
.contain /deep/.el-button--primary {
  background-color: #154d72;
  border-color: #154d72;
  width: 80%;
  text-align: center;
  margin: 28px auto;
  display: block;
}
</style>
