<template>
  <div class="loginbox" v-if="show">
    <div class="dialog">
      <div class="dialog-mode">
        <div class="dialog-top">
          <h-panel width="210px" :lineShow="true" :pstyle="pstyle" marginRight="14px">登录</h-panel>
          <h-panel :lineShow="true" :pstyle="topStyle"><img src="@/assets/img/close.png" alt="" class="close" @click="handleClose"/></h-panel>
        </div>
        <!-- @click="()=>{return show=false}" -->
        <div>
          <el-form ref="form" :model="form" label-width="10px" :rules="rules">
            <div class="form-item">
              <h-panel width="100%" :lineShow="true">
                <div class="login_bottom">
                  <el-form-item prop="username" class="form_item">
                    <!-- <div>账户</div> -->
                    <el-input v-model="form.username" placeholder="请输入账户" :autofocus="true"></el-input>
                  </el-form-item>
                  <div class="login_line"></div>
                  <el-form-item prop="password" class="form_item">
                    <!-- <div>密码</div> -->
                    <el-input v-model="form.password" placeholder="请输入密码" type="password"></el-input>
                  </el-form-item>
                  <div class="login_line"></div>
                </div>
              </h-panel>
            </div>

            <h-panel width="100%" :lineShow="true">
              <el-button class="form-button" type="primary" @click="submitForm('form')">登录</el-button>
            </h-panel>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HPanel from './panel.vue'
import { postLogin } from '@/api/api.js'
export default {
  components: {
    HPanel
  },
  props: {
    tabList: {},
    date: {},
    status: {}
  },
  data() {
    return {
      show: false,
      num: 0,
      tShow: false,
      pstyle: {
        textAlign: 'center',
        padding: '9px 0',
        fontFamily: 'YouSheBiaoTiHei',
        fontStyle: ' normal',
        fontWeight: '400',
        fontSize: '26px',
        letterSpacing: '0.16em',
        color: '#FFFFFF',
        textShadow: '0px 0px 4px #4074FF'
      },
      topStyle: {
        background: 'none',
        textAlign: 'right',
        padding: '9px 0',
        fontFamily: 'YouSheBiaoTiHei',
        fontStyle: ' normal',
        fontWeight: '400',
        fontSize: '26px',
        letterSpacing: '0.16em',
        color: '#FFFFFF',
        borderColor: '#0D53B7',
        textShadow: '0px 0px 0px #4074FF'
      },
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [{ required: true, message: '请输入账户', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  mounted() {},
  methods: {
    tabShow(type) {
      if (type == 'back') {
        this.$router.replace('/')
      } else {
        this.tShow = !this.tShow
      }
    },
    //登录
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // { password: 1, username: 1 }
          if (this.form.username !== 'xiaopingfang') {
            this.$message.error('账号/密码不对')
            return
          }
          postLogin(this.form)
            .then(res => {
              console.log(res)
              if (res.code == '0') {
                sessionStorage.clear()
                this.show = false
                this.baseForm = res.model
                this.initData = res.model
                // sessionStorage.setItem('userInfo', JSON.stringify(res.model))
                //登录窗登录
                sessionStorage.setItem('isFormLogin', 'isFormLogin')
                //是否是小平房管理员登陆
                if (this.form.username == 'xiaopingfang') sessionStorage.setItem('isPoster', 'isPoster')
                this.resetSetItem('userInfo', JSON.stringify(res.model))
                sessionStorage.setItem('token', res.model.tokenValue)
                this.form = {}
                // this.$router.replace('/')
                this.$emit('login')
              } else {
                this.$message.error(res.message)
              }
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          return false
        }
      })
    },
    handleClose() {
      this.show = false
      this.form = {}
    }
  }
}
</script>
<style lang="scss" scope>
.flex_start {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
}
.flex_space {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}
.flex_center {
  display: flex !important;
  justify-content: center;
}
.flex_center_align {
  display: flex !important;
  align-items: center !important;
}
.dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.68);
  z-index: 9;
  .dialog-mode {
    width: 30%;
    position: relative;
    margin: 21vh auto 50px;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 30%);
    box-sizing: border-box;
  }
  .login_bottom {
    width: 80%;
    height: auto;
    margin: 60px auto 47px;
  }
  .form-button {
    background-color: transparent;
    border-color: transparent;
    width: 100%;
    height: 100%;
    font-family: 'YouSheBiaoTiHei';
    font-weight: 400;
    font-size: 26px;
  }
  .form-button:hover,
  .form-button:focus {
    background-color: transparent;
    border-color: transparent;
  }
  .close {
    width: 18px;
    height: 18px;
    margin-right: 21px;
    cursor: pointer;
  }
  .dialog-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .login_line {
    width: 100%;
    height: 1px;
    opacity: 0.2;
    background-image: radial-gradient(#fff, transparent);
    box-shadow: 0px 0px 4px #4074ff;
    transform: rotate(-180deg);
  }
}
</style>
<style>
.loginbox .el-input {
  font-size: 24px;
}

.loginbox .form-item {
  width: 100%;
  margin: 15px 0;
}
.login_bottom .el-form-item {
  margin-bottom: 2px;
}
.loginbox .el-input .el-input__inner {
  background-color: transparent !important;
  font-size: 24px;
  color: #fff;
  border-radius: 0;
  border: none;
  height: 60px;
  line-height: 60px;
}
.loginbox input:-webkit-autofill {
  font-size: 24px !important;
  color: #fff !important;
}
.loginbox input:-internal-autofill-previewed,
.loginbox input:-internal-autofill-selected {
  font-size: 24px !important;
  color: #fff !important;
  background-color: transparent !important;
  -webkit-text-fill-color: #fff;
  transition: background-color 1000s ease-out 0.5s;
}
</style>
