import request from '@/utils/request'
// 查询天气
export function getTianQi(data) {
  return request({
    url: 'https://tianqiapi.com/free/day',
    method: 'get',
    params: data
  })
}
export function getTianQiNew(data) {
  return request({
    url: '/api/getWeatherInfo',
    method: 'get',
    params: data
  })
}
// 获取token
export function getTokenVideo(data) {
  return request({
    url: 'https://open.ys7.com/api/lapp/token/get',
    method: 'post',
    params: data
  })
}

// 获取token
export function postLogin(data) {
  return request({
    url: '/username/login',
    method: 'post',
    params: data
  })
}
// 查询菜单
export function getMenus(data) {
  return request({
    url: '/screenTab/search',
    method: 'get',
    params: data
  })
}
// 查询菜单
export function getHomeDetail(data) {
  return request({
    url: '/screenHomeSection/loadScreenHomeSection',
    method: 'get',
    params: data
  })
}
// 查询菜单
export function getOthers(data) {
  return request({
    url: '/screenSection/loadScreenSection',
    method: 'get',
    params: data
  })
}
// 查询首页显示地址
export function getMaps(cParentId) {
  return request({
    url: '/regionInfo/search',
    method: 'get',
    params: { pid: cParentId }
  })
}
// 查询户
export function getResidents(params) {
  return request({
    url: '/familyMaintain/getFamilyMaintainList',
    method: 'get',
    params: params
  })
}
// 查询户详情
export function getResidentDetail(data) {
  return request({
    url: '/resident/getResidentDetail',
    method: 'get',
    params: data
  })
}

// 查询户详情
export function getpqDetail(params) {
  return request({
    url: '/areaManagement/search',
    method: 'get',
    params: params
  })
}

// 根据村组查询村组下的摄像头
export function selectGroupCamera(params) {
  return request({
    url: '/camera/selectGroupCamera',
    method: 'get',
    params: params
  })
}

// 查片区下所有摄像头
export function selectCameraByRegion(params) {
  return request({
    url: '/camera/selectCameraByRegion',
    method: 'get',
    params: params
  });
}

// 查重点摄像头
export function selectMajorCamera(params) {
  return request({
    url: '/camera/selectMajorCamera',
    method: 'get',
    params: params
  });
}

//查询学习排行
export function learnRanking(params) {
  return request({
    url: '/bookUserLog/searchAll',
    method: 'get',
    params: params
  })
}

//查询读物排行TOP10
export function getBookRankings(params) {
  return request({
    url: '/bookClickLog/getBookRankings',
    method: 'get',
    params: params
  })
}


export function selectTree(params) {
  return request({
    url: '/camera/selectTree',
    method: 'get',
    params: params
  })
}

// 获取网格员接口
export function getAdminUser(params) {
  return request({
    url: '/adminUser/getAdminUser',
    method: 'get',
    params: params
  })
}

// 获取首页统计数据
export function getIndexStatistics(params) {
  return request({
    url: '/adminUser/getIndexStatistics',
    method: 'post',
    params: params
  })
}

//  获取首页住户分析
export function getIndexAnalysis(params) {
  return request({
    url: '/adminUser/getIndexAnalysis',
    method: 'post',
    params: params
  })
}

export function getIndexAnalysisType(params) {
  return request({
    url: '/adminUser/getIndexAnalysisType',
    method: 'post',
    params: params
  })
}

export function getAnalysis(params) {
  return request({
    url: '/adminUser/getIndexFeedingInfo',
    method: 'post',
    params: params
  })
}

export function getHisItems(params) {
  return request({
    url: '/villageHistory/search',
    method: 'get',
    params: params
  })
}

//按年分组查询村史
export function getHisItemsGroupByYera(params) {
  return request({
    url: '/villageHistory/searchGroupByYera',
    method: 'get',
    params: params
  })
}

// 查询村史
export function getHisItemsGroup(params) {
  return request({
    url: '/villageHistory/searchAll',
    method: 'get',
    params: params
  })
}

//党建引领模块右侧数据
export function getDangIndex(params) {
  return request({
    url: '/partyBranchMember/getRightData',
    method: 'get',
    params: params
  })
}

//党支部位置
export function getDangArea(params) {
  return request({
    url: '/partyBranch/searchAll',
    method: 'get',
    params: params
  })
}
//党支部内分类数量
export function getDangNums(params) {
  return request({
    url: '/partyBranchMember/getMemBerCountByBranchId',
    method: 'get',
    params: { branchId: params }
  })
}
//党支部内分类数量
export function getDangNews(params) {
  return request({
    url: '/articleInfo/search',
    method: 'get',
    params: params
  })
}

//地图查询标注点
export function getMapAll(params) {
  return request({
    url: '/model/searchAll',
    method: 'get',
    params: params
  })
}
//查询3d地图弹窗展示详情
export function getMapDetails(params) {
  return request({
    url: '/model/searchRichText',
    method: 'get',
    params: params
  })
}
//查询3d地图设备弹窗展示详情
export function getMapCameras(params) {
  return request({
    url: '/camera/' + params.id,
    method: 'get',
  })
}

//查询3d地图网格信息
export function getMapGrids(params) {
  return request({
    url: '/familyMaintain/getFamilyMaintainListByModel',
    method: 'get',
    params: params
  })
}

//村情信息查询全部网格员
export function getAllGrids(params) {
  return request({
    url: '/areaManagement/searchGridman',
    method: 'get',
    params: params
  })
}

//查询村委班子/党委班子的成员
export function getAllMembers(params) {
  return request({
    url: '/partyBranchMember/searchAll',
    method: 'get',
    params: params
  })
}

//查询全部党支部
export function getAllPartys(params) {
  return request({
    url: '/partyBranch/searchAll',
    method: 'get',
    params: params
  })
}
//查询党支部成员
export function getAllPartyMembers(params) {
  return request({
    url: '/partyBranchMember/searchAll',
    method: 'get',
    params: params
  })
}
//查询党员基本情况
export function getAllPartyTable(params) {
  return request({
    url: '/partyBranchMember/memberWay',
    method: 'get',
    params: params
  })
}

//查询护林员等
export function getAllForests(params) {
  return request({
    url: '/forestRanger/searchAll',
    method: 'get',
    params: params
  })
}

//查询年龄比例
export function getAllRatio(params) {
  return request({
    url: '/resident/searchAreaProp',
    method: 'get',
    params: params
  })
}

//查询村委
export function getAllCommittee(params) {
  return request({
    url: '/villageCommittee/searchAll',
    method: 'get',
    params: params
  })
}

//查询党员
export function getAllPartyer(params) {
  return request({
    url: '/partyBranchMember/getMemBerGroup',
    method: 'get',
    params: params
  })
}


//查询所有点
export function getAllPoints(params) {
  return request({
    url: '/screenSection/loadSectionDetailAll',
    method: 'get',
    params: params
  })
}

//查询阅读量
export function getRead(params) {
  return request({
    url: '/bookPageviewsLog/statisticsPageView',
    method: 'get',
    params: params
  })
}

//  查询文章列表
export function articleInfoList(ob) {
  return request({
    url: '/articleInfo/search',
    method: 'get',
    params: ob
  })
}
//  查询文章详情
export function getArticleInfo(ob) {
  return request({
    url: '/articleInfo/' + ob,
    method: 'get',
  })
}
//  查询道德银行列表
export function getBankList(ob) {
  return request({
    url: '/moralityRequest/searchByUser',
    method: 'get',
    params: ob
  })
}
//  查询在线课堂列表
export function getLineClass(ob) {
  return request({
    url: '/bookUserLog/getOnlineUser',
    method: 'get',
    params: ob
  })
}
//  查询价格行情
export function goodsInfoList(ob) {
  return request({
    url: '/goodsInfo/search',
    method: 'get',
    params: ob
  })
}

//  查询价格行情
export function getNewNews(ob) {
  return request({
    url: '/villagerTrendsLog/searchAll',
    method: 'get',
    params: ob
  })
}

//  查询未读数量
export function searchReadNums(ob) {
  return request({
    url: '/villagerTrendsLog/selectGridRead',
    method: 'get',
    params: ob
  })
}
//  清除未读数量
export function clearReadNums(ob) {
  return request({
    url: '/villagerTrendsLog/readOperate',
    method: 'get',
    params: ob
  })
}
//  清除未读数量
export function searchNewsList(ob) {
  return request({
    url: '/villagerTrendsLog/searchToDay',
    method: 'get',
    params: ob
  })
}


//  所有分组查询
export function villageListTeam(ob) {
  return request({
    url: '/areaManagement/searchAll',
    method: 'get',
    params: ob
  })
}

// 查询辽宁省下市场
export function searchMarkets(ob) {
  return request({
    url: '/goodsInfo/selectData',
    method: 'get',
    params: ob
  })
}

// 查询市场价格
export function searchPriceList(ob) {
  return request({
    url: '/goodsInfo/searchAll',
    method: 'get',
    params: ob
  })
}

// 查询车辆详情记录
export function vipCarList (ob) {
  return request({
    url: '/numberplateAccess/searchAll',
    method: 'get',
    params: ob
  })
}

// 查询车辆出入日期
export function vipCarDateList (ob) {
  return request({
    url: '/numberplateAccess/searchDays',
    method: 'get',
    params: ob
  })
}
