
import { clearReadNums } from '@/api/api';
import mapJosn from '@/utils/cun.json';
var districtLoading = 0;
var blist = []
const mixins = {
  data() {
    return {
      iconImg: require('@/assets/img/icon.png'),
      marker: null,
      changerow: '',
      countries: null
    }
  },
  computed: {

  },
  methods: {
    /*
    =====获取行政区域边界=====
    */
    getBoundary() {
      // this.addDistrict("辽宁省朝阳市");
      this.addDistrict("辽宁省朝阳市朝阳县");
      // this.addDistrict("辽宁省朝阳市朝阳县");
    },
    addDistrict(districtName) {
      let that = this;
      //使用计数器来控制加载过程
      districtLoading++;
      var bdary = new T.Boundary();
      bdary.get(districtName, function (rs) {       //获取行政区域
        var count = rs.boundaries.length; //行政区域的点有多少个
        for (var i = 0; i < count; i++) {
          blist.push({ points: rs.boundaries[i], name: districtName });
        }
        //加载完成区域点后计数器-1
        districtLoading--;
        if (districtLoading == 0) {
          //全加载完成后画端点
          that.drawBoundary();
        }
      });
    },
    click(evt) {
      alert(evt.target.name);
    },
    drawBoundary() {
      let that = this;
      //包含所有区域的点数组
      var pointArray = [];
      //循环添加各闭合区域
      for (var i = 0; i < blist.length; i++) {
        //添加多边形层并显示
        var ply = new T.Polygon(blist[i].points, {
          strokeWeight: 2,   //边框宽度
          trokeColor: "#FFA96E",   //边框颜色
          fillColor: " #000000" //填充颜色
        }); //建立多边形覆盖物
        ply.name = blist[i].name;
        // ply.addEventListener("click", that.click);
        this.wxMap.addOverlay(ply);

        //将点增加到视野范围内
        var path = ply.getPath();
        pointArray = pointArray.concat(path);
      }
      this.wxMap.setViewport(pointArray);    //调整视野
    },
    clearMarker() {
      var newMarker = this.wxMap.getOverlays() // 获取到了地图上的所有点
      for (var n = 0; n < newMarker.length; n++) {
        this.wxMap.removeOverLay(newMarker[n])
      }
    },
    // setmarker(points, infoWindows, label, e) {
    setmarker(e, marker, label) {
      // 标注的点击事件
      // console.log(e);
      if (e.mode || e.articleContent) {
        marker.addEventListener("click", (event) => {
          this.showCunMode(e);
        });
        label.addEventListener('click', (event) => {
          this.showCunMode(e);
        })
      }

    },
    showCunMode(data) {
      if (data.type == 'gridType') {
        this.$refs.cunMode.itemtype = 'grid'
        // return
      } else if (data.type == 'party') {
        // 党建
        console.log(data);
        this.$refs.cunMode.initData(data);
        return
      } else if (data.type == 'videoType') {
        console.log('shipin ');
        this.openVideo(data);
        return
      }
      this.$refs.cunMode.initData(data);
    },
    // showCunMode(data) {
    //   console.log(data);
    //   if (data.type == 'gridType') {
    //     this.$refs.cunMode.itemtype = 'grid'
    //     // return
    //   } else if (data.type == 'party') {
    //     // 党建
    //     getDangNums(data.id).then(res => {
    //       if (res.code == '0') {
    //         let item = {
    //           formalMember: res.model.formalMember,
    //           prepareMember: res.model.prepareMember,
    //           developMember: res.model.developMember,
    //           flowMember: res.model.flowMember,
    //           bedMember: res.model.bedMember,
    //           armyMember: res.model.armyMember,
    //           ...data,
    //         }
    //         // console.log(item);
    //         this.$refs.cunMode.initData(item);
    //       }
    //     })
    //     return
    //   }
    //   this.$refs.cunMode.initData(data);
    // },
    openVideo(item) {
      console.log(item)
      this.$refs.ezuik.initData(item);
    },
    showWeiYuan() {
      let data = {
        name: '中共朝阳县波罗赤镇委员会',
        lx: 'table',
        tableList: this.dangYuanList
      }
      this.$refs.cunMode.initData(data);
    },
    showJieShaoYuan() {
      let data = {
        name: '波罗赤镇介绍',
        lx: 'jieshao',
        word: '波罗赤镇位于朝阳县的西大门，全镇下辖八个行政村，79个村民组，6432户18457口人。是一个以农业和畜牧业为主导产业的乡镇，波罗赤镇发挥农业产业优势，以特色强农、数字兴农、生态富农开创高质量发展新局面，绘就乡村振兴崭新画卷。  波罗赤镇把数字化乡村建设作为推动高质量发展的有效抓手。与大连众晖科技集团签订数字乡村综合运营框架合作协议，实施乡村振兴综合智慧能源项目建设。该项目以数字化农村产业经济为中心，党建和文化输出为基础，分阶段，分层次的引入多方位的资源到农村产业链中，做到种植，养殖，环保，生产，销售，绿色金融，品牌营销于一体的助力赋能，通过智慧农业、智慧牧业等科学方法提高农村的生产效能。'
      }
      this.$refs.cunMode.initData(data);
    },
    setMapSetting(data) {
      let icon = require('@/assets/map/icon.png')
      if (data.length > 0) {
        data.forEach((item) => {
          // 创建标注对象
          // if (!item.longitude && !item.latitude || !item.lgt && !item.lat) {
          //   return
          // }
          const marker = new T.Marker(new T.LngLat(item.longitude || item.lgt, item.latitude || item.lat), {
            icon: new T.Icon({
              // iconUrl: require('@/assets/map/icon.png'), // 引入自定义图标
              iconUrl: item.icon ? require('@/assets/map/' + item.icon + '.png') : icon, // 引入自定义图标
              iconSize: new T.Point(30, 32), // 图标大小
            }),
          })
          marker.id = 'pink' // 给图标加个名字  方便删除
          // console.log(marker, 'marker');
          // 向地图上添加标注
          this.wxMap.addOverLay(marker)

          let label = new T.Label({
            text: `<span>${item.regionName || item.name || item.branchName}<span>`,
            //点坐标位置
            position: marker.getLngLat(),
            offset: new T.Point(1.5, 0),
          })
          label.setBackgroundColor('rgba(14,95,255, 0.5)')
          label.setBorderColor('#0d53b7')
          label.getBorderLine(0)
          label.setFontSize('14px')
          label.setZindex(9)
          label.setFontColor('#fff')
          this.wxMap.addOverLay(label)
          // this.loadText()
          // 点位的点击事件，展示弹窗
          this.setmarker(item, marker, label)
        });
      }
    },
    changItem(item) {
      this.clearMarker();
      this.changerow = item;
      // item.point = item.list
      let data = item.point || item.list;
      this.setMapSetting(data);
    },
    // 区域图
    setRegion(data) {
      let mapjson = ''
      // console.log('data');
      // console.log(data);
      data.forEach((item) => {
        // console.log(item.gridding);
        if (item.gridding) {
          mapjson = JSON.parse(item.gridding)
          var countriesOverlay = new T.D3Overlay(this.initDraw, this.redraw)
          var map = this.wxMap
          this.countries = mapjson.features
          map.addOverLay(countriesOverlay)
          countriesOverlay.bringToBack()
        }
      })
    },
    // 面
    initDraw(sel, transform) {
      // debugger
      var upd = sel.selectAll('path.geojson').data(this.countries)
      upd.enter()
        .append('path')
        .attr('class', 'geojson')
        .attr('stroke', 'rgb(231,231,231)')
        .attr('fill', function (d, i) {
          // return d3.hsl(Math.random() * 3000, 0.9, 0.5) // 区域图色彩范围
          return d3.hsl(214, 0.9, 0.5)// 区域图色彩范围
        })
        .attr('fill-opacity', '0.3') // 区域图透明度
    },
    redraw(sel, transform) {
      sel.selectAll('path.geojson').each(function (d, i) {
        d3.select(this).attr('d', transform.pathFromGeojson)
      })
    },
    initBorder(initMap) {
      var bdary = mapJosn.features[0].geometry.coordinates[0]
      var pointArrays = new Array()
      for (var i = 0; i < bdary.length; i++) {
        var xyArr = bdary[i][0]
        var ptAr = bdary[i][1]
        pointArrays.push(new T.LngLat(xyArr, ptAr))
      }
      var border = new T.Polygon(pointArrays, { color: '#2299EE', weight: 5, opacity: 1, fillColor: '#FFFFFF', fillOpacity: 0 })

      //向地图上添加线
      this.wxMap.addOverLay(border)
    },
    // 清除数量
    clearRead(type) {
      clearReadNums({ trendsType: type }).then(res => { })
    },
    //千分位
    numberToCurrencyNo(value) {
      if (!value) return 0
      // 获取整数部分
      const intPart = Math.trunc(value)
      // 整数部分处理，增加,
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      // 预定义小数部分
      let floatPart = ''
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split('.')
      if (valueArray.length === 2) { // 有小数部分
        floatPart = valueArray[1].toString() // 取得小数部分
        return intPartFormat + '.' + floatPart
      }
      return intPartFormat + floatPart
    },
    loginForm() {
      console.log('222');
      if (sessionStorage.getItem('isFormLogin')) {
        this.status = true
      }

      if (sessionStorage.getItem('isPoster')) {
        this.canSee = true
      }
      setTimeout(function () {
        window.location.reload()
      }, 10)
    }

  },
  created() {

  }
}

export default mixins
